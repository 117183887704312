/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useClientAuth } from '../../providers/ClientProvider';
import Vega_Button from '../../components/common/v3/Vega_Button';
import VegapayLogo from '../../components/icon/VegapayLogo';
import Vega_TextField from '../../components/common/v3/Vega_TextField';
import { PRIMARY } from '../../constants/style';
import { getEnv } from '../../constants/url';
import { ENV } from '../../enums/commons';

type LoginContainerProps = {
  changePageHandler: (state: string) => void;
};

type LoginForm = {
  email: string;
  password: string;
};

function LoginContainer({ changePageHandler }: LoginContainerProps) {
  const { login } = useClientAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [reCaptchaValue, setReCaptchaValue] = useState<string | null>(null);

  const loginForm = useForm<LoginForm>({ mode: 'onBlur' });
  const { control, formState } = loginForm;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const submitForm = (
    data: LoginForm,
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    const { email, password } = data;
    login(e, email, password);
  };

  const showReCaptcha = () => {
    const currentEnv = getEnv();
    const isPreProdOrDev = currentEnv == ENV.PRE_PROD || currentEnv == ENV.DEV;
    return !isPreProdOrDev;
  };

  const checkForCaptchaValue = () => {
    const isFormValid = formState.isValid;
    if (showReCaptcha() == false) {
      return isFormValid;
    }
    return isFormValid && !reCaptchaValue;
  };

  useEffect(() => {
    console.log({
      env: getEnv(),
    });
  }, []);

  return (
    <Box
      sx={{
        maxWidth: isMobile ? '330px' : '400px',
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        ...(isMobile
          ? {
              paddingBottom: '48px',
            }
          : {
              paddingTop: '100px',
              paddingBottom: '60px',
            }),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <VegapayLogo />
        <Box
          sx={{
            width: '100%',
            marginTop: '84px',
          }}
        >
          <Typography
            fontSize={17}
            className="font-aspekta-600"
            textAlign="center"
          >
            Login with your email ID
          </Typography>
          <FormProvider {...loginForm}>
            <Box
              my={3}
              component={'form'}
              onSubmit={loginForm.handleSubmit(submitForm)}
            >
              <Box>
                <Typography
                  fontSize={12}
                  textTransform="uppercase"
                  className="font-aspekta-700"
                  color="#676B76"
                  paddingBottom={1}
                >
                  Email ID
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  }}
                  render={({ field }) => (
                    <Vega_TextField
                      {...field}
                      type="email"
                      key="login-email"
                      helperText={
                        formState.errors.email ? 'Invalid email' : ' '
                      }
                      placeholder="Enter email address"
                      error={!!formState.errors.email}
                      endAdornment={<></>}
                    />
                  )}
                />
              </Box>
              <Box mb={3}>
                <Typography
                  fontSize={12}
                  textTransform="uppercase"
                  className="font-aspekta-700"
                  color="#676B76"
                  paddingBottom={1}
                >
                  Password
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Vega_TextField
                      key={'login-pass'}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                  )}
                />
                {showReCaptcha() && (
                  <Box my={2.5}>
                    <ReCAPTCHA
                      sitekey="6LeLhHUnAAAAACftMS55hwpQuSTw1yBgN0ccMB7f"
                      onChange={setReCaptchaValue}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    textAlign: 'end',
                  }}
                >
                  <Typography
                    fontSize={12}
                    className="font-aspekta-600"
                    sx={{
                      color: PRIMARY.darkBlue,
                      cursor: 'pointer',
                    }}
                    component={'span'}
                    onClick={() => changePageHandler('FORGOT')}
                    textAlign={'center'}
                  >
                    Forgot Password ?
                  </Typography>
                </Box>
              </Box>
              <Stack spacing={2}>
                <Vega_Button
                  type="submit"
                  text="Login"
                  disabled={!checkForCaptchaValue()}
                  sx={{
                    paddingY: 1.2,
                    fontSize: 14,
                    lineHeight: '130%',
                    ':disabled': {
                      bgcolor: '#bbbbbb',
                      opacity: 1,
                    },
                  }}
                />
              </Stack>
            </Box>
          </FormProvider>
        </Box>
      </Box>
      <Stack
        sx={{
          display: 'flex',
          gap: 3,
          flexDirection: 'row',
        }}
      >
        <Typography
          sx={{
            color: '#616E7F',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: 12,
            textDecoration: 'underline',
          }}
          onClick={() =>
            window.open('https://www.vegapay.tech/terms-and-condition.html')
          }
        >
          terms and conditions
        </Typography>
        <Typography
          sx={{
            color: '#616E7F',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: 12,
            textDecoration: 'underline',
          }}
          onClick={() =>
            window.open('https://www.vegapay.tech/privacy-policy.html')
          }
        >
          privacy policy
        </Typography>
      </Stack>
    </Box>
  );
}

export default LoginContainer;
