import { ENV } from '../enums/commons';
import { DASHBOARD_ROUTES } from '../routes';
import { getURLPrefix } from '../utils/common.utils';

export const URL = {
  // prod
  CLIENT: 'www.client.vegapay.tech',
  PROGRAM: 'www.program.vegapay.tech',
  SIMULATOR: 'www.simulator.vegapay.tech',
  BACK_OFFICE: 'www.back-office.vegapay.tech',

  // sandbox
  SANDBOX_CLIENT: 'www.client.sandbox.vegapay.tech',
  SANDBOX_PROGRAM: 'www.program.sandbox.vegapay.tech',
  SANDBOX_SIMULATOR: 'www.simulator.sandbox.vegapay.tech',
  SANDBOX_BACK_OFFICE: 'www.back-office.sandbox.vegapay.tech',

  // dev
  DEV: 'www.dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',

  // current
  CURRENT: window.location.hostname,

  // localhost
  LOCAL: 'localhost',

  // forex
  FOREX_BRANCH: 'www.branch.vegapay.tech',
  FOREX_CORPORATE: 'www.corporate.vegapay.tech',

  // unity
  UNITY_PROGRAM: 'www.program.unity.vegapay.tech',

  PRE_PROD_PROGRAM: 'program.unity-uat.vegapay.tech',
};

export const HOSTNAME = {
  // prod
  CLIENT: 'client.vegapay.tech',
  PROGRAM: 'program.vegapay.tech',
  SIMULATOR: 'simulator.vegapay.tech',
  BACK_OFFICE: 'back-office.vegapay.tech',

  // sandbox
  SANDBOX_CLIENT: 'client.sandbox.vegapay.tech',
  SANDBOX_PROGRAM: 'program.sandbox.vegapay.tech',
  SANDBOX_SIMULATOR: 'simulator.sandbox.vegapay.tech',
  SANDBOX_BACK_OFFICE: 'back-office.sandbox.vegapay.tech',

  // dev
  DEV: 'dev.vegapay.tech',
  DEV_BASE: 'dev.vegapay.tech',

  // current
  CURRENT: window.location.hostname,

  // localhost
  LOCAL: 'localhost',

  // forex
  FOREX_BRANCH: 'branch.vegapay.tech',
  FOREX_CORPORATE: 'corporate.vegapay.tech',

  // unity
  UNITY_PROGRAM: 'program.unity.vegapay.tech',
  PRE_PROD_PROGRAM: 'program.unity-uat.vegapay.tech',
};

export const ACCESS_MAP: any = {
  // prod
  'www.client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'client.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'www.program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'program.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'simulator.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'www.back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'back-office.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,

  // sandbox
  'client.sandbox.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'www.client.sandbox.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'program.sandbox.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.program.sandbox.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'simulator.sandbox.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'www.simulator.sandbox.vegapay.tech': DASHBOARD_ROUTES.SIMULATOR,
  'back-office.sandbox.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,
  'www.back-office.sandbox.vegapay.tech': DASHBOARD_ROUTES.BACK_OFFICE,

  // dev
  'www.dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  'dev.vegapay.tech': DASHBOARD_ROUTES.CLIENT_DASHBOARD,

  // mcc
  'branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'www.branch.vegapay.tech': DASHBOARD_ROUTES.MCC_BACK_OFFICE,
  'corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,
  'www.corporate.vegapay.tech': DASHBOARD_ROUTES.MCC_CORPORATE,

  // unity
  'program.unity.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.program.unity.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,

  // pre prod unity
  'program.unity-uat.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
  'www.program.unity-uat.vegapay.tech': DASHBOARD_ROUTES.PROGRAM_DASHBOARD,

  // localhost
  localhost: DASHBOARD_ROUTES.BACK_OFFICE,
  // localhost: DASHBOARD_ROUTES.SIMULATOR,
  // localhost: DASHBOARD_ROUTES.CLIENT_DASHBOARD,
  // localhost: DASHBOARD_ROUTES.PROGRAM_DASHBOARD,
};

export const DATE_FORMATE = 'DD-MM-YYYY';
export const DATE_FORMATE_SHORT = 'DD/MM/YY';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY - hh:mm:ss a';
export const DATE_TIME_SHORT_FORMAT = 'DD/MM/YY hh:mm a';
export const DATE_FORMAT_US = 'YYYY-MM-DD';
export const DATE_FORMAT_LETTERS = 'MMM D, YYYY';
export const DATE_FORMAT_MONTH_US = 'YYYY-MM';

export type DashboardURLs = keyof typeof URL;
export const currentHost = window.location.hostname;

export const getEnv = (): ENV => {
  // return ENV.SANDBOX;
  // return ENV.PROD;
  console.log("Current host: " + currentHost);
  switch (currentHost) {
    case URL.DEV:
    case URL.DEV_BASE:
    case URL.LOCAL:
      return ENV.DEV;
    case URL.SANDBOX_BACK_OFFICE:
    case URL.SANDBOX_CLIENT:
    case URL.SANDBOX_PROGRAM:
    case URL.SANDBOX_SIMULATOR:
      return ENV.SANDBOX;
    case URL.PRE_PROD_PROGRAM:
      return ENV.PRE_PROD;
    default:
      return ENV.PROD;
  }
};

export const isDev = getEnv() === ENV.DEV;

export const isLocalPath = URL.CURRENT === URL.LOCAL;
export const isCurrentPathForexBranch = URL.CURRENT === URL.FOREX_BRANCH;
export const isCurrentPathForexCorporate = URL.CURRENT === URL.FOREX_CORPORATE;

export const isCurrentPathClient = [URL.CLIENT, URL.SANDBOX_CLIENT].includes(
  URL.CURRENT
);

export const isCurrentPathSimulator = [
  URL.SIMULATOR,
  URL.SANDBOX_SIMULATOR,
].includes(URL.CURRENT);

export const isCurrentPathProgram = [
  URL.PROGRAM,
  URL.SANDBOX_PROGRAM,
  URL.UNITY_PROGRAM,
  URL.PRE_PROD_PROGRAM,
].includes(URL.CURRENT);

export const isCurrentPathBackOffice = [
  URL.BACK_OFFICE,
  URL.SANDBOX_BACK_OFFICE,
].includes(URL.CURRENT);

export const getUrl = (
  hostname: 'CLIENT' | 'PROGRAM' | 'SIMULATOR' | 'BACK_OFFICE'
) => {
  const key = (getURLPrefix() + hostname) as DashboardURLs;
  console.log('====================================');
  console.log({
    key: key,
    hostname: hostname,
    URLPrefix: getURLPrefix(),
  });
  console.log('====================================');
  return `https://${URL[key]}`;
};
