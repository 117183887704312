import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { IAPIStatus, IErrorStatus } from '../types/commonType';
import { ICustomer } from '../types/customers';
import { DATE_FORMAT_US, getEnv } from '../constants/url';
import { DATE_FILTERS, ENV } from '../enums/commons';
import SearchIcon from '../components/icon/SearchIcon';
import { ListGroupHeadingTypo } from '../components/common/Typography';
import { MouseEvent } from 'react';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NEUTRALS } from '../constants/style';
import CalenderIconV5 from '../components/icon/CalenderIconV5';
import React from 'react';
dayjs.extend(localeData);

export function getFormData(object: Record<string, any>) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] != null && object[key] !== undefined) {
      Array.isArray(object[key])
        ? (object[key] as Array<any>).forEach((element) => {
            element != null &&
              element !== undefined &&
              formData.append(key, element);
          })
        : formData.append(key, object[key]);
    }
  });
  return formData;
}

export const getCustomerName = (customer: ICustomer | null) => {
  if (!customer) return '';

  const fName = customer?.firstName || '';
  const mName = customer?.middleName || '';
  const lName = customer?.lastName || '';

  return (fName + ' ' + mName + ' ' + lName).trim();
};

export function getEmptyFields(obj: Object) {
  const emptyFields = Object.entries(obj)
    .filter(([key, value]) => value === '')
    .map(([key]) => key);

  return emptyFields;
}

export const getAPICommonPendingStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: true,
    isError: false,
  } as IAPIStatus;
};

export const getAPICommonRejectedStatus = (
  currentAPIStatus: IAPIStatus = {},
  defErrMsg?: string
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: true,
    errMessage: defErrMsg || 'Unknown Error',
  } as IAPIStatus;
};

export const getAPICommonFulfilledStatus = (
  currentAPIStatus: IAPIStatus = {}
) => {
  return {
    ...currentAPIStatus,
    isLoading: false,
    isError: false,
    isFirstCallDone: true,
  } as IAPIStatus;
};

export const standard500ErrResponse = (err?: any) => {
  const errData = err?.response?.data?.apierror;
  return {
    apierror: (errData || {
      status: err?.code,
      message: err?.message,
    }) as IErrorStatus,
  };
};

export const getAllYearsBetween = (
  start: number,
  end?: number,
  rev?: boolean
) => {
  let endUpdate = end;
  if (!endUpdate) {
    endUpdate = new Date().getFullYear();
  }
  if (start >= endUpdate) return [];
  const res = [];
  if (rev) {
    for (let i = endUpdate; i >= start; i--) {
      res.push(i);
    }
  } else {
    for (let i = start; i <= endUpdate; i++) {
      res.push(i);
    }
  }
  return res;
};

export const getAllMonthsBetween = (
  startMonthNum?: number,
  endMonthNum?: number,
  rev?: boolean
) => {
  let months = (dayjs.months() as Array<string>).slice(
    startMonthNum || 0,
    endMonthNum || 12
  );
  if (rev) {
    months = months.reverse();
  }
  return months;
};

export const getDateRange = (
  dateUpto: DATE_FILTERS,
  format = DATE_FORMAT_US
) => {
  const today = dayjs();

  switch (dateUpto) {
    case DATE_FILTERS['7D']:
      return {
        startDate: today.subtract(7, 'day').format(format),
        endDate: today.format(format),
      };
    case DATE_FILTERS['15D']:
      return {
        startDate: today.subtract(15, 'day').format(format),
        endDate: today.format(format),
      };
    case DATE_FILTERS['30D']:
      return {
        startDate: today.subtract(30, 'day').format(format),
        endDate: today.format(format),
      };
    case DATE_FILTERS['3M']:
      return {
        startDate: today.subtract(3, 'month').format(format),
        endDate: today.format(format),
      };
    case DATE_FILTERS['6M']:
      return {
        startDate: today.subtract(6, 'month').format(format),
        endDate: today.format(format),
      };
    case DATE_FILTERS['12M']:
      return {
        startDate: today.subtract(1, 'year').format(format),
        endDate: today.format(format),
      };
    default:
      return {
        startDate: dayjs('2021-12-23').format(format),
        endDate: today.format(format),
      };
  }
};

export const TextFieldSearchProps = {
  header: 'customer',
  standardWidth: 300,
  InputProps: { endAdornment: <SearchIcon /> },
  headerTypo: ListGroupHeadingTypo,
};

export const getDateInputIcons = (
  showCloseIcon: boolean,
  onClose: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
) => {
  return {
    endAdornment: showCloseIcon ? (
      <InputAdornment position="end" onClick={onClose}>
        <CloseIcon
          sx={{ color: NEUTRALS.grey[300], fontSize: '15px' }}
          className="cursor-pointer"
        />
      </InputAdornment>
    ) : undefined,
    startAdornment: (
      <InputAdornment position="start">
        <CalenderIconV5 className="cursor-pointer" />
      </InputAdornment>
    ),
  };
};

export const getURLPrefix = () => {
  const env = getEnv();
  switch (env) {
    case ENV.DEV:
      return 'DEV_';
    case ENV.SANDBOX:
      return 'SANDBOX_';
    case ENV.PRE_PROD:
      return 'PRE_PROD_';
    default:
      return '';
  }
};
