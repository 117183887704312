export enum EntityStatusEnum {
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
}

export enum CheckerMakerStatusEnum {
  REVIEWER_PENDING = 'REVIEWER_PENDING',
  CHECKER_PENDING = 'CHECKER_PENDING',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum CustomerTypeEnum {
  RETAIL_CUSTOMER = 'RETAIL_CUSTOMER',
  CORPORATE_CUSTOMER = 'CORPORATE_CUSTOMER',
}

export enum CustomerStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  DRAFT = 'DRAFT',
  UNDER_REVIEW = 'UNDER_REVIEW',
  REJECTED = 'REJECTED',
}

export enum KycStatus {
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
}

export enum CustomerType {
  DEPENDANT = 'DEPENDANT',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum CustomerIdentityTypes {
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  VOTER_ID = 'VOTER_ID',
}

export enum TransferType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CompanyTypeEnum {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PRIVATE_LIMITED = 'PRIVATE_LIMITED',
  PARTNERSHIP_FIRMS = 'PARTNERSHIP_FIRMS',
}

export enum PartnerTypeEnum {
  CO_BRAND = 'CO_BRAND',
  CO_LENDING = 'CO_LENDING',
}

export enum CONSTANTS {
  SELECTED_VIEW = 'view',
}

export enum CARD_REISSUE_REASON {
  BROKEN = 'BROKEN',
  RENAME = 'RENAME',
  CLIENT_ORDER = 'CLIENT_ORDER',
  SHAVED = 'SHAVED',
  RELIEF_LOSS = 'RELIEF_LOSS',
  LOST = 'LOST',
  ROBBED = 'ROBBED',
  THEFT = 'THEFT',
  UNRECEIVED = 'UNRECEIVED',
  DAMAGED = 'DAMAGED',
  DEFECT = 'DEFECT',
  INCORRECT_NAME = 'INCORRECT_NAME',
}

export enum GovernmentIdType {
  PAN = 'PAN',
  AADHAAR = 'AADHAAR',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
}

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export enum INDIAN_STATES {
  ANDHRA_PRADESH = 'Andhra Pradesh',
  ARUNACHAL_PRADESH = 'Arunachal Pradesh',
  ASSAM = 'Assam',
  BIHAR = 'Bihar',
  CHHATTISGARH = 'Chhattisgarh',
  GOA = 'Goa',
  GUJARAT = 'Gujarat',
  HARYANA = 'Haryana',
  HIMACHAL_PRADESH = 'Himachal Pradesh',
  JHARKHAND = 'Jharkhand',
  KARNATAKA = 'Karnataka',
  KERALA = 'Kerala',
  MADHYA_PRADESH = 'Madhya Pradesh',
  MAHARASHTRA = 'Maharashtra',
  MANIPUR = 'Manipur',
  MEGHALAYA = 'Meghalaya',
  MIZORAM = 'Mizoram',
  NAGALAND = 'Nagaland',
  ODISHA = 'Odisha',
  PUNJAB = 'Punjab',
  RAJASTHAN = 'Rajasthan',
  SIKKIM = 'Sikkim',
  TAMIL_NADU = 'Tamil Nadu',
  TELANGANA = 'Telangana',
  TRIPURA = 'Tripura',
  UTTARAKHAND = 'Uttarakhand',
  UTTAR_PRADESH = 'Uttar Pradesh',
  WEST_BENGAL = 'West Bengal',
  LADAKH = 'Ladakh',
  JAMMU_AND_KASHMIR = 'Jammu and Kashmir',
  PUDUCHERRY = 'Puducherry',
  LAKSHADWEEP = 'Lakshadweep',
  ANDAMAN_AND_NICOBAR_ISLANDS = 'Andaman and Nicobar Islands',
  DNHDD = 'DNHDD',
  CHANDIGARH = 'Chandigarh',
  DELHI = 'Delhi',
}

export enum QUERY_NAMES {
  CUSTOMER_ACCOUNT_LIST = 'customer-account-list',
  CUSTOMER_LIST = 'customer-list',
  LEDGER_LIST = 'ledger-list',
  INSTALLMENT_LIST = 'installment-list',
  SURPLUS_LIST = 'surplus-list',
  WAIVER_LIST = 'waiver-list',
  MCC_INFO = 'mcc-info',
  CUSTOMER_AUDIT = 'customer-audit',
  TRANSACTION_LIST_ACCOUNT = 'transaction-list-account',
  TRANSACTION_LIST = 'transaction-list',
  TRANSACTION_JOURNEY = 'transaction-journey',
  TRANSACTION_CHARGES_LIST = 'transaction-charges-list',
  TRANSACTION_CHARGES_LIST_BY_ACC = 'transaction-charges-list-by-acc',
  UNWAIVED_TRANSACTION_CHARGES_LIST_BY_ACC = 'transaction-charges-list-by-acc',
  TRANSACTION_REFUNDS_LIST = 'transaction-refunds-list',
  TRANSACTION_CHARGEBACK_LIST = 'transaction-chargeback-list',
  BILL_LIST = 'bill-list',
  BILL_LIST_BO = 'bill-list-bo',
  BILL_TRANSACTION_LIST_BO = 'bill-transaction-list-bo',
  BILL_EMIs_LIST_BO = 'bill-emis-list-bo',
  BILL_LOAN = 'bill-loan',
  BILL_REPAYMENTS_LIST_BO = 'bill-repayments-list-bo',
  EMI_LIST = 'emi-list',
  REWARD_LIST = 'reward-list',
  EMI_LIST_FROM_LOAN_ID = 'emi-list-from-loan-id',
  LOAN_FROM_LOAN_ID = 'loan-from-loan-id',
  ANALYTICS_DATA = 'analytics-data',
  CARD_LIST = 'card-list',
  PHYSICAL_STATEMENT_LIST = 'physical-statement-list',
  APPLICATIONS = 'applications',
  DELINQUENCY_HISTORY_LIST = 'delinquency-history-list',
  DELINQUENCY_STATS_LIST = 'delinquency-stats-list',
  DELINQUENCY_CURRENT_LIST = 'delinquency-current-list',
  MID_CODE = 'mid-code',
  MCC_LIST = 'mcc-list',
  MERCHANT_NAME_LIST = 'merchant-name-list',
}

export enum GENDER {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum TITLES {
  MR = 'Mr.',
  MS = 'Ms.',
  MRS = 'Mrs.',
  DR = 'Dr.',
  PROF = 'Prof.',
}

export enum COUNTRY_CODES {
  INDIA = '+91',
}

export enum STORE_SLICE {
  HEADER = 'header',
  SIMULATOR = 'simulator',
  PROGRAM_MANAGEMENT = 'program_management',
  ACCOUNT_CLOSURE = 'account_closure',
}

export enum DATE_FILTERS {
  '7D' = '7D',
  '15D' = '15D',
  '30D' = '30D',
  '3M' = '3M',
  '6M' = '6M',
  '12M' = '12M',
  All = 'All',
}

export enum ENV {
  DEV = 'DEV',
  PROD = 'PROD',
  SANDBOX = 'SANDBOX',
  PRE_PROD = 'PRE_PROD',
}

export enum COUNTRY {
  INDIAN = 'INDIAN',
}
